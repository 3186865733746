import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import styled, { css } from "styled-components";
import { IIcon } from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  IGlobalPalleteColor,
  TColorId,
  TLinkId,
  TLinkReadoutValue,
  TLinkSet,
  TTypoId,
  TTypoReadoutValue,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import composeCss from "../../../settingsPatterns/composeCss";
import {
  ILinkContent,
  SelectedGoToOption,
} from "../../../settingsPatterns/contentPatterns.types";
import { convertColorIdToHex } from "../../../settingsPatterns/settingsPatterns.methods";
import { useLinkContentActions } from "../../../shared/hooks/useLinkContentAction";
import { PortalPath } from "../../../shared/portalPath/portalPath";
import ConditionalHTMLElement from "../ConditionalHTMLElement/ConditionalHTMLElement";

export interface IDynamicLinkWrapperProps<T> {
  link: ILinkContent;
  children: T;
  linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorId;
  path?: PortalPath;
  icon?: IIcon;
  show?: boolean;
  onClick?: () => void;
  isActive?: boolean;
  isActiveForced?: boolean;
}

interface IStyledLinkWrapper {
  $linkStyle?: TLinkReadoutValue | TLinkId | "basic";
  $fontStyle?: TTypoReadoutValue | TTypoId;
  basicColor?: TColorId;
}

const generateLinkStyle = (
  linkStyle: TLinkReadoutValue | TLinkId | "basic",
  globalLink: TLinkSet,
  colorP: IGlobalPalleteColor[],
  isActive?: boolean
): string => {
  if (linkStyle === "basic") {
    return `color: blue; cursor: pointer; :hover { text-decoration: underline }`;
  } else {
    return composeCss.link(linkStyle, globalLink, colorP, isActive);
  }
};

export const StyledDynamicLinkWrapper = styled(ConditionalHTMLElement).attrs(
  (props) => ({
    renderAs: props?.["renderAs"] ?? "a",
  })
)<IStyledLinkWrapper>`
  ${(props) => {
    const {
      $linkStyle,
      $fontStyle,
      $basicColor,
      $isActive,
      icon,
      theme: { globalObjects, colorPalette, typography },
    } = props;
    return css`
      ${$linkStyle !== undefined
        ? generateLinkStyle(
            $linkStyle,
            globalObjects.links,
            colorPalette,
            $isActive
          )
        : ""}
      ${$fontStyle !== undefined ? composeCss.font($fontStyle, typography) : ""}
    ${$basicColor !== undefined
        ? `color: ${convertColorIdToHex($basicColor, colorPalette)};`
        : ""};
      ${icon ? composeCss.buttonSettings.icon(icon) : ""};
    `;
  }}
`;

const DynamicLinkWrapper: React.FC<
  IDynamicLinkWrapperProps<React.ReactNode>
> = ({
  link,
  children,
  linkStyle,
  fontStyle,
  basicColor,
  path,
  icon,
  show = true,
  onClick,
  isActive,
  isActiveForced,
}) => {
  const { getHref, linkContentActions, getTarget } =
    useLinkContentActions(path);

  const router = useRouter();
  if (!show || link === null || link === undefined) {
    return null;
  }

  const href = getHref(link);

  if (href === undefined) {
    return (
      <StyledDynamicLinkWrapper
        role="link"
        onClick={() => {
          onClick && onClick();
          linkContentActions(link);
        }}
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
        $isActive={isActiveForced}
      >
        {children}
      </StyledDynamicLinkWrapper>
    );
  }

  const isActiveUrl =
    isActive ??
    (link.goToPageSetting?.link &&
      router?.asPath?.endsWith(link.goToPageSetting.link));

  return link.selectedGoToOption === SelectedGoToOption.GO_TO_PAGE ? (
    <Link href={href} passHref>
      <StyledDynamicLinkWrapper
        role="link"
        renderAs="div"
        onClick={() => {
          onClick && onClick();
          linkContentActions(link);
        }}
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
        rel="noreferrer"
        target={getTarget(link)}
        $isActive={isActiveUrl}
      >
        {children}
      </StyledDynamicLinkWrapper>
    </Link>
  ) : (
    <div
      className="link_action__wrapper"
      onClick={() => linkContentActions(link)}
    >
      <StyledDynamicLinkWrapper
        role="link"
        $linkStyle={linkStyle}
        $fontStyle={fontStyle}
        $basicColor={basicColor}
        icon={icon}
        $isActive={isActiveUrl}
      >
        {children}
      </StyledDynamicLinkWrapper>
    </div>
  );
};

export default DynamicLinkWrapper;
